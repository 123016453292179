import { AiFillHtml5 } from 'react-icons/ai';
import { FaGithub, FaNodeJs } from 'react-icons/fa';
import { SiJavascript, SiJquery, SiCss3, SiSass, SiBootstrap, SiReact, SiVisualstudiocode, SiPython, SiPostgresql, SiPhp } from 'react-icons/si';
import { BsWordpress } from 'react-icons/bs';
import { AiOutlineConsoleSql } from 'react-icons/ai';
import { IconContext}  from 'react-icons';

const Skills = () => {
    
    return (
        <section className="container px-5 skills" id='skills'>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-sm-9 col-md-4 text-center d-flex justify-content-around flex-column pb-5'>
                    <div className=''>
                        <h2 className='' id='skills'>Skills</h2>
                        <div className='underline'/>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-9 col-md-8 text-center d-flex justify-content-around flex-column pb-5'>
                    <p className='skills-header-text'>I'm proficient with the following technologies:</p>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                    <svg width="0" height="0">
                        <linearGradient id="blue-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
                            <stop stopColor="#626262" offset="0%" />
                            <stop stopColor="#626262" offset="100%" />
                        </linearGradient>
                    </svg>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}> 
                        <AiFillHtml5 style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            HTML
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}> 
                        <SiBootstrap style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            Bootstrap
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}> 
                        <SiCss3 style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            CSS
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}> 
                        <SiJavascript style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            JavaScript
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}>
                        <SiVisualstudiocode style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            VS Code
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}> 
                        <SiReact style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            React
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}>
                        <FaGithub style={{ fill: "url(#blue-gradient)" }} size='43%' /> 
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            GitHub
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}> 
                        <SiJquery style={{ fill: "url(#blue-gradient)" }} size='43%' /> 
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            jQuery
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}>
                        <SiReact style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            React Native
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}>
                        <AiOutlineConsoleSql style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            SQL
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}>
                        <FaNodeJs style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            Node.js
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}>
                        <SiPython style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            Python
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}>
                        <SiPhp style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            PHP
                        </p>
                    </div>
                </div>
                <div className='col-custom d-flex justify-content-center align-items-center'>
                    <IconContext.Provider value={ {className: "icon"}}>
                        <BsWordpress style={{ fill: "url(#blue-gradient)" }} size='43%' />
                    </IconContext.Provider>
                    <div className='skill-overlay'>
                        <p className='skill-text'>
                            WordPress
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Skills;