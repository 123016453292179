import React from 'react';
import { useState } from 'react';
import {  Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import { projectsData } from '../data/projects-data';

const Projects = () => {
    const [modalIsVisible, setModalIsVisible] = useState(false);
    const [modalData, setModalData] = useState('');
        
    const imgClickHandler = (e, projectData) => {
        e.preventDefault();
        setModalData(projectData);
        setModalIsVisible(!modalIsVisible);
    };

    const closeModal = () => {
        setModalData('');
        setModalIsVisible(!modalIsVisible);
    }

    const ProjectItem = ({ projectData }) => {

        return (
            <div className='col-12 col-xl-6 mb-3'>
                <div className='row px-3 px-sm-5 px-xl-3'>
                    <div className='col-12 col-md-6 px-0 pl-md-2'>
                        {/* <a type='button' className='project-link' href="#projects" onClick={(e) => imgClickHandler(e, projectData)}><img src={projectData.img} className='img-fluid project-img' alt={projectData.imgAlt}/></a> */}
                        {
                            projectData.youtubeLink ? (<a type='button' className='project-link' href="#projects" onClick={(e) => imgClickHandler(e, projectData)}><img src={projectData.img} className='img-fluid project-img' alt={projectData.imgAlt}/></a>) : projectData.modalImg ? 
                            (<a type='button' className='project-link' href={projectData.modalImg}  target="_blank" rel="noreferrer"><img src={projectData.img} className='img-fluid project-img' alt={projectData.imgAlt}/></a>)
                            : (<img src={projectData.img} className='img-fluid project-img' alt={projectData.imgAlt}/>)
                        }
                    </div>
                    <div className='col-12 col-md-6 text-container projects-text-container d-flex justify-content-between flex-column'>
                        <div>
                            <div className='row d-flex justify-content-center my-0'>
                                <div className='col-12 my-0'>
                                    <h3 className='pb-2 pb-md-1 pb-lg-2 pt-0 m-0 text-center project-detail-header'>{projectData.header}</h3>
                                    <div className='project-detail-text-container'>
                                        <p className='mb-1 project-detail-text'>
                                        {projectData.text}
                                    </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center my-1'>
                                <div className='col-12 py-1'>
                                    <div className='underline underline-2'/>
                                </div>
                            </div>
                        <div>            
                            {
                                projectData.versions === 1 ? (
                                    <div className='row d-flex justify-content-center my-2'>
                                <div className='col-12 text-center'>
                                    <a role="button" rel='noreferrer' target="_blank" className='project-btn-link' href={projectData.github1}><button type="button" className='main-btn main-btn-1 project-btn text-nowrap'>GitHub</button></a>
                                </div>
                                {projectData.website1 !== '' ? (<div className='col-12 text-center'>
                                    <a role="button" rel='noreferrer' target="_blank" className='project-btn-link' href={projectData.website1}><button type="button" className='main-btn main-btn-1 project-btn text-nowrap'>View Live</button></a>
                                </div>) : <div/>}
                            </div>
                                ) : (
                                    <div className='row d-flex justify-content-center'>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-12 text-center'>
                                                    <p className='text-start text-nowrap project-type-text'>Bootstrap:</p>
                                                    <a role="button" rel='noreferrer' target="_blank" className='project-btn-link' href={projectData.github1}><button type="button" className='main-btn main-btn-1 project-btn text-nowrap'>GitHub</button></a>
                                                </div>
                                                <div className='col-12 text-center'>
                                                    <a role="button" rel='noreferrer' target="_blank" className='project-btn-link' href={projectData.website1}><button type="button" className='main-btn main-btn-1 project-btn text-nowrap'>View Live</button></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-12 text-center'>
                                                    <p className='text-start project-type-text'>React:</p>
                                                    <a role="button" rel='noreferrer' target="_blank" className='project-btn-link' href={projectData.github2}><button type="button" className='main-btn main-btn-1 project-btn text-nowrap'>GitHub</button></a>
                                                </div>
                                                <div className='col-12 text-center'>
                                                    <a role="button" rel='noreferrer' target="_blank" className='project-btn-link' href={projectData.website2}><button type="button" className='main-btn main-btn-1 project-btn text-nowrap'>View Live</button></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } 
                        </div> 
                    </div>
                </div>
            </div> 
        );
    }

    return (
        <React.Fragment>
            <section className="container-fluid projects" id='projects'>
                <div className='row d-flex justify-content-center mx-5'>
                    <div className='col-12 col-sm-9 col-md-4 text-center d-flex justify-content-around flex-column pb-5'>
                        <div className=''>
                            <h2 className='' id='projects'>Projects</h2>
                            <div className='underline'/>
                        </div>
                    </div>
                </div>
                <div className='row d-flex justify-content-center'>
                <div className='col-9 col-md-8 text-center d-flex justify-content-around flex-column pb-5'>
                    <p className='skills-header-text'>A selection of original projects designed and coded by me. </p>
                    <p><i>Note: For the Windows Clone Calculator the design elements are drawn from the original. In other projects the References section of the Readme.md file will notate if other sources for design inspiration or WordPress patterns were utilized in the project.</i></p>
                </div>
            </div>
                <div className='row d-flex justify-content-center'>
                    <div className='col-11 col-sm-9 text-center d-flex justify-content-around flex-column pb-5'>
                        {/* <div className=''> */}
                            <h3 className='projects-subheader'>Websites:</h3>
                        {/* </div> */}
                    </div>
                </div>
                <div className='row d-flex justify-content-between mx-xl-5'>
                    {projectsData.filter(project => project.type === 'website').map(project => <ProjectItem projectData={project} key={project.id} imgClickHandler={imgClickHandler} />)}
                </div>
                <div className='row d-flex justify-content-center'>
                    <div className='col-11 col-sm-9 text-center d-flex justify-content-around flex-column p-5'>
                        {/* <div className=''> */}
                            <h3 className='projects-subheader'>Single-Page Applications and Mini Projects:</h3>
                        {/* </div> */}
                    </div>
                </div>
                <div className='row d-flex justify-content-between mx-xl-5'>
                    {projectsData.filter(project => project.type === 'mini-project').map(project => <ProjectItem projectData={project} key={project.id} />)}
                </div>
                {/* <div className='row d-flex justify-content-center'>
                    <div className='col-11 col-sm-9 text-center d-flex justify-content-around flex-column p-5'>
                            <h3 className='projects-subheader'>JavaScript Applications:</h3>
                    </div>
                </div>
                <div className='row d-flex justify-content-between mx-xl-5'>
                    {projectsData.filter(project => project.type === 'javascript').map(project => <ProjectItem projectData={project} key={project.id} />)}
                </div> */}
                {/* <div className='row d-flex justify-content-center'>
                    <div className='col-11 col-sm-9 text-center d-flex justify-content-around flex-column p-5'>
                            <h3 className='projects-subheader'>WordPress Websites:</h3>
                    </div>
                </div>
                <div className='row d-flex justify-content-center mx-xl-5'>
                    {projectsData.filter(project => project.type === 'wordpress').map(project => <ProjectItem projectData={project} key={project.id} />)}
                </div> */}
            </section>
            <Modal isOpen={modalIsVisible} toggle={closeModal} size="lg" style={{maxWidth: '1000px', width: '97%', maxHeight: '80%' }} className=''>
                <ModalHeader toggle={closeModal}> 
                <div className='d-flex flex-row justify-content-center align-items-center'>
                    <p className='my-auto pl-1'>{modalData.header} Video</p>
                </div>
                </ModalHeader>
                <ModalBody>
                    <div className='d-flex flex-row justify-content-center align-items-center'>
                        {/* {
                            modalData.videoType === 'webm' || modalData.videoType === 'mp4' ? (
                                <div>
                                    <video className="project-video" onloadedmetadata="muted = true" playsinline autoplay="autoplay" loop="loop" muted>
                                        <source src={modalData.videoLink} type="video/webm"/>
                                        Your browser is not supported! 
                                    </video>
                                </div>
                            ) : (
                                <img src={modalData.videoLink} className='img-fluid' alt={modalData.imgAlt}/>
                            )
                        } */}
                        {/* If Using Youtube Videos */}
                        {
                            modalData.youtubeLink ? (<div className='youtube-video-container'>
                            <iframe src={modalData.youtubeLink} title={modalData.header} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;
                            fullscreen; picture-in-picture" 
                            allowfullscreen="allowfullscreen"
                            mozallowfullscreen="mozallowfullscreen" 
                            msallowfullscreen="msallowfullscreen" 
                            oallowfullscreen="oallowfullscreen" 
                            webkitallowfullscreen="webkitallowfullscreen"
                            ></iframe>
                        </div>) : (<p>Sorry, no video is available at this time.</p>)
                        }
                        
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="main-btn main-btn-2 mr-3 main-btn-2-link" onClick={closeModal}>Close</button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default Projects;