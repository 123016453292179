import Navigation from './components/Navbar';
import Header from './components/Header';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contact from './components/Contact';
// import './App.css';
import Footer from './components/Footer';

function App() {

  return (
    <>
      <Navigation />
      <Header />
      <About />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
      {/* <div className='vh-100'></div> */}
    </>
  );
}

export default App;
