const About = () => {
    return (
        <section className="container-fluid about" id='about'>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-sm-9 col-md-4 text-center d-flex justify-content-around flex-column'>
                    <div className=''>
                        <h2 className='' id='about'>About Me</h2>
                        <div className='underline'/>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center align-items-center px-md-5 mx-lg-5'>
                <div className='col-8 col-md-8 col-xl-5 text-center mt-3 mt-md-5 text-container'>
                    <p>
                        Hello and welcome to my portfolio page!
                    </p>
                    <p>
                        I recently graduated from Nucamp's Full Stack and Backend bootcamps.
                        I'm experienced in building projects with HTML, CSS, JavaScript, Bootstrap, React, React Native, SQL, Python, and more. I love coding as I find it to have the perfect combination of being intellectually challenging yet still structured.
                    </p>
                    <p>
                        Thanks for taking the time to view my portfolio.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default About;