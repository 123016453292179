import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import { GrMail } from 'react-icons/gr';
import { FaGithubSquare } from 'react-icons/fa';
import { MdPictureAsPdf } from 'react-icons/md';
import resume from "../assets/pdf/Ameral_Michelle_Resume.pdf";

const Header = () => {
    return (
        <>
            <header>
                <div className='container-fluid'>
                    <div className='row d-flex justify-content-center align-items-center header'>
                        <div className='col-12 text-center'>
                            <div className='header-title-wrapper'>
                                <HeaderIntro />
                            </div>
                            <p className='header-subtitle'>Web Development & Database</p>
                            <div className='d-flex justify-content-center'>
                                <a role="button" className='main-btn-1-link' href="#projects"><button type='button' className='main-btn main-btn-1 mr-3 text-nowrap'><span>View My Work</span></button></a>
                                <a role="button" rel='noreferrer' target="_blank" className='main-btn-2-link' href={ resume }><button type='button' className='main-btn main-btn-2 mr-3 text-nowrap'><span>Resume</span></button></a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className='side-wrapper'>
                <div className='d-flex flex-column align-items-center justify-content-center'>
                    <div className="my-1">
                        <a role="button" className="icon-btn" href="mailto:michelleameral@gmail.com">
                            <GrMail size='35px' />
                        </a>
                    </div>
                    <div className='my-1'>
                        <a role="button" rel='noreferrer' target="_blank" className='icon-btn' href="https://github.com/MichelleAaa">
                            <FaGithubSquare size='35px' />
                        </a>
                    </div>
                    <div className='my-1'>
                        <a role="button" className='icon-btn'  rel='noreferrer' target="_blank" href={ resume }>
                            <MdPictureAsPdf size='35px' />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

const HeaderIntro = () => {
    return (
        <ReactTypingEffect
            text={["Hi, I'm Michelle."]}
        />
    );
};

export default Header;