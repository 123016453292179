
const Footer = () => {
    
    return (
        <section className="container-fluid">
            <div className='row d-flex justify-content-center align-items-center site-footer'>
                <div className='col-6 d-flex justify-content-center'>
                    <p className='footer-text'>Designed and Coded by Michelle.</p>
                </div>
            </div>
        </section>
    );
}

export default Footer;