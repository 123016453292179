import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

const Contact = () => {
    return (
        <section className="container px-xl-5 section" id='contact'>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-sm-9 col-md-4 text-center d-flex justify-content-around flex-column pb-5'>
                    <div className=''>
                        <h2 className='' id='contact'>Contact</h2>
                        <div className='underline'/>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-9 col-md-8 text-center d-flex justify-content-around flex-column pb-5'>
                    <p className='skills-header-text'>If you have any questions about my projects or would like to get in touch, please feel free to email me at <a className='contact-email' href="mailto:michelleameral@gmail.com"> michelleameral@gmail.com</a> or send me a message via the provided form.</p>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-10 col-md-8 col-lg-6 col-xl-5'>
                    <ContactForm />
                </div>
            </div>
        </section>
    );
}

function ContactForm() {
    const [state, handleSubmit] = useForm("xyyoozwr");
    if (state.succeeded) {
        return (
            <p className='contact-success'>Thank you, your message has been sent.</p>
        );
    }
    return (
        <form onSubmit={handleSubmit} className='contact-form'>
            <label htmlFor="name" className="form-label mt-1">
                Name
            </label>
            <input
                id="name"
                type="text" 
                name="name"
                className="form-control"
                required
            />
            <label htmlFor="email" className="form-label mt-1">
                Email Address
            </label>
            <input
                id="email"
                type="email" 
                name="email"
                className="form-control"
                pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
                title="youremailaddress@domain.com"
                required
            />
            <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
                required
            />
            <label htmlFor="message" className="form-label mt-1">
                Message
            </label>
            <textarea
                id="message"
                name="message"
                className="form-control"
                rows='6'
                required
            />
            <ValidationError 
                prefix="Message" 
                field="message"
                errors={state.errors}
            />
            <button type="submit" disabled={state.submitting} className='main-btn main-btn-1 contact-btn'>
                Submit
            </button>
        </form>
    );
}

export default Contact;