import imgtypology from '../assets/projects/typology/typology-home-page.jpeg';
// import videotypology from '../assets/projects/typology/typology-live.webm';
import imglabustoursco from '../assets/projects/la-bus-tours-co/la-bus-tours-home.jpeg';
// import videolabustoursco from '../assets/projects/la-bus-tours-co/la-bus-tours-live.webm';
import imgexpensetracker from '../assets/projects/expense-tracker/expense-tracker.jpeg';
// import videoexpensetracker from '../assets/projects/expense-tracker/expense-tracker-live.webm';
import imgnewsapi from '../assets/projects/news-api/news-api.png';
// import videonewsapi from '../assets/projects/news-api/newsapi-app-live.webm';
import imgcalculator from '../assets/projects/clone-calculator/clone-calculator.jpeg';
// import videocalculator from '../assets/projects/clone-calculator/calculator-live.webm';
import imgquiz from '../assets/projects/computing-trivia-quiz/computing-trivia-quiz.jpeg';
// import videoquiz from '../assets/projects/computing-trivia-quiz/computing-trivia-quiz-live-1.webm';
// import imgnumtoroman from '../assets/projects/roman-numeral-generator/number-to-roman-numeral.jpeg';
// import videonumeral from '../assets/projects/roman-numeral-generator/number-to-r-numeral-live.gif';
// import imgtodo from '../assets/projects/to-do-list/to-do-list.jpeg';
// import videotodo from '../assets/projects/to-do-list/to-do-list-live-1.webm';
import imgtictactoegame from "../assets/projects/tic-tac-toe/python-turtle-tic-tac-toe-game.png";
// import videotictactoegame from "../assets/projects/tic-tac-toe/python-turtle-tic-tac-toe-game-live.mp4";
import imgschooldatabase from "../assets/projects/school-database/school-database-img.jpeg";
import modalimgschooldatabase from "../assets/projects/school-database/school-database-diagram2.png";
// import videoschooldatabase from "../assets/projects/school-database/school-database-live.mp4";
import imgabctutoringcompany from "../assets/projects/abc-tutoring-company/abc-tutoring-company.jpeg";
import imgtheofficesupplyoutlet from "../assets/projects/the-office-supply-outlet/the-office-supply-outlet-home.jpeg";

export const projectsData = [
    {
        id: 0,
        type: 'website',
        img: imgtheofficesupplyoutlet,
        imgAlt: 'The Office Supply Outlet Image',
        header: 'The Office Supply Outlet',
        text: "A WordPress custom block theme website for an office supply company. Built with HTML, CSS, PHP, JavaScript, Bootstrap, and more. There are two custom post types, one to add/update FAQ data in the FAQ accordions and another to add/update the heading sliders, which are located in a Bootstrap carousel. The project utilizes the WooCommerce plugin for the shop functionality, the WP Menu Cart plugin for the cart icon and 'View Cart' buttons, the Advanced Custom Fields plugin for additional fields in the header-slides custom post type, and several patterns from wordpress.org. The project also features a custom block plugin called Suggested Blog Posts, which allows the user to add links to other suggested articles. The plugin was built with CSS, PHP, JavaScript, React (@wordpress/element), and other modules.",
        versions: 1,
        github1: "https://github.com/MichelleAaa/the-office-supply-outlet#suggested-blog-posts-plugin",
        website1: "https://theofficesupplyoutlet.000webhostapp.com/",
        // videoType: 'mp4',
        // videoLink: videoschooldatabase,
        youtubeLink: 'https://www.youtube.com/embed/G1mOTj8yA68',
        modalImg: null
    },
    {
        id: 1,
        type: 'website',
        img: imgtypology,
        imgAlt: 'Typology Mug Shoppe Website Image',
        header: 'Typology Mug Shoppe',
        text: 'An e-commerce store built with React, React Router Dom 6, Redux with Toolkit, Bootstrap, Reactstrap, Framer Motion,  VanillaJS, JSX, CSS and more.',
        versions: 1,
        github1: "https://github.com/MichelleAaa/typology-mug-shoppe",
        website1: "https://michelleameral.com/typology-mug-shoppe/",
        // videoType: 'mp4',
        // videoLink: videotypology,
        youtubeLink: 'https://www.youtube.com/embed/Q0rtywNN9Vo',
        modalImg: null
    },
    {
        id: 2,
        type: 'website',
        img: imglabustoursco,
        imgAlt: 'L.A. Bus Tours Co. Website Image',
        header: 'L.A. Bus Tours Co.',
        text: 'Initially, the website was built with Bootstrap, Sass, CSS and a little jQuery. Afterwards, a React implementation was also created which utlizes Reactstrap, React-Router-Dom, Bootstrap, CSS, and jQuery.',
        versions: 2,
        github1: "https://github.com/MichelleAaa/labustoursco",
        website1: "https://michelleameral.com/labustoursco/",
        github2: "https://github.com/MichelleAaa/labustoursco-react-revised",
        website2: "https://michelleameral.com/labustoursco-react-revised/",
        // videoType: 'mp4',
        // videoLink: videolabustoursco,
        youtubeLink: 'https://www.youtube.com/embed/IzbVILCo3c0',
        modalImg: null
    },
    {
        id: 3,
        type: 'website',
        img: imgabctutoringcompany,
        imgAlt: 'ABC Tutoring Co. Website Image',
        header: 'ABC Tutoring Company',
        text: "A WordPress custom classic theme website built with PHP, Bootstrap, and CSS. The project features two custom post types and a custom search query. The weForms plugin was used for the form on the Contact Us page.",
        versions: 1,
        github1: "https://github.com/MichelleAaa/abc-tutoring-company",
        // website1: "https://abctutoringco.000webhostapp.com/",
        website1: '',
        // videoType: 'mp4',
        // videoLink: videoschooldatabase,
        youtubeLink: 'https://www.youtube.com/embed/6BBXwkbNIjM',
        modalImg: null
    },
    {
        id: 4,
        type: 'mini-project',
        img: imgexpensetracker,
        imgAlt: 'Expense Tracker Image',
        header: 'Front & Back End Expense Tracker',
        text: 'An expense tracker application to track transactions.  Frontend built with React, JavaScript, JSX, Bootstrap, Reactstrap, Axios , and CSS. Backend built with Node.js, Express, MongoDB, and Mongoose.',
        versions: 1,
        github1: "https://github.com/MichelleAaa/node.js-expense-tracker",
        website1: "https://expense-tracker.cyclic.app",
        // videoType: 'webm',
        // videoLink: videoexpensetracker,
        youtubeLink: 'https://www.youtube.com/embed/Fe8foikhmw4',
        modalImg: null
    },
    // {
    //     id: 6,
    //     type: 'javascript',
    //     img: imgtodo,
    //     imgAlt: 'Fill in Later',
    //     header: 'To-Do List',
    //     text: "A web-based CRUD application which allows users to add, remove, and update items on a to-do list. Built with JavaScript, Bootstrap, and CSS.",
    //     versions: 1,
    //     github1: "https://github.com/MichelleAaa/js-to-do-list",
    //     website1: "https://github.com/MichelleAaa/js-to-do-list",
    //     videoType: 'webm',
    //     videoLink: videotodo
    // },
    // {
    //     id: 7,
    //     type: 'javascript',
    //     img: imgnumtoroman,
    //     imgAlt: 'Fill in Later',
    //     header: 'Number to Roman Numeral Generator',
    //     text: "A web-based application featuring a number to roman numeral generator. Built with JavaScript, Bootstrap, and CSS.",
    //     versions: 1,
    //     github1: "https://github.com/MichelleAaa/js-number-to-roman-numeral-generator",
    //     website1: "https://github.com/MichelleAaa/js-number-to-roman-numeral-generator",
    //     videoType: 'gif',
    //     videoLink: videonumeral
    // },
    {
        id: 5,
        type: 'mini-project',
        img: imgtictactoegame,
        imgAlt: 'Tic Tac Toe Game Image',
        header: 'Python Tic Tac Toe Game',
        text: "A python application which utilizes the turtle module to render a tic tac toe game.",
        versions: 1,
        github1: "https://github.com/MichelleAaa/python-turtle-tic-tac-toe",
        website1: "https://replit.com/@michelleaaa/tic-tac-toe-game#main.py",
        // videoType: 'mp4',
        // videoLink: videotictactoegame,
        youtubeLink: 'https://www.youtube.com/embed/mGHRyVafpsI',
        modalImg: null
    },
        {
        id: 6,
        type: 'mini-project',
        img: imgnewsapi,
        imgAlt: 'React Native News App Image',
        header: 'React Native News Application',
        text: "A mobile application featuring a general news screen and search term functionality. Built with React Native, Expo, Axios, and more. The app makes use of NewsAPI.",
        versions: 1,
        github1: "https://github.com/MichelleAaa/react-native-news-app",
        website1: "",
        // videoType: 'webm',
        // videoLink: videonewsapi,
        youtubeLink: 'https://www.youtube.com/embed/GHAXy3Lf5hs',
        modalImg: null
    },
    {
        id: 7,
        type: 'mini-project',
        img: imgcalculator,
        imgAlt: 'Calculator image',
        header: 'Clone Calculator',
        text: "A web-based application which clones most of the UI elements and some of the basic features and functionality of the Windows operating system's built-in calculator. This project was built with JavaScript, jQuery, Bootstrap, and CSS.",
        versions: 1,
        github1: "https://github.com/MichelleAaa/js-and-jquery-calculator",
        website1: "https://michelleameral.com/js-and-jquery-calculator/",
        // videoType: 'webm',
        // videoLink: videocalculator,
        youtubeLink: 'https://www.youtube.com/embed/X8EXJOqPZ9c',
        modalImg: null
    },
    {
        id: 8,
        type: 'mini-project',
        img: imgschooldatabase,
        imgAlt: 'School Database Drawio Image',
        header: 'School Database with SQL',
        text: "An Entity Relationship diagram of a school database made with drawio and an implementation of the database in SQL.",
        versions: 1,
        github1: "https://github.com/MichelleAaa/school-database",
        website1: "https://www.db-fiddle.com/f/97vQBySNXSJnFCjj2mtagS/3",
        // videoType: 'mp4',
        // videoLink: videoschooldatabase,
        youtubeLink: null,
        modalImg: modalimgschooldatabase
    },
    {
        id: 9,
        type: 'mini-project',
        img: imgquiz,
        imgAlt: 'Quiz Image',
        header: 'Computing Trivia Quiz',
        text: "A web-based application featuring a computing trivia quiz. Built with JavaScript, Bootstrap, and CSS. Makes use of the Open Trivia Database.",
        versions: 1,
        github1: "https://github.com/MichelleAaa/js-api-trivia-quiz",
        website1: "https://michelleameral.com/js-api-trivia-quiz/",
        // videoType: 'webm',
        // videoLink: videoquiz,
        youtubeLink: 'https://www.youtube.com/embed/4_C9IOuk720',
        modalImg: null
    }
];
