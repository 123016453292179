import React, { useState } from 'react';
import { Navbar, NavbarToggler, Nav, NavItem, Collapse } from 'reactstrap';

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar
            color="light"
            expand="lg"
            fixed='top'
            light
        >
            <NavbarToggler onClick={toggle}  />
            <Collapse isOpen={isOpen} navbar>
            <Nav
                className="ms-auto text-nowrap"
                navbar
            >
                <NavItem>
                    <a href="#about" onClick={toggle}>About</a>
                </NavItem>
                <NavItem>
                    <a href="#skills" onClick={toggle}>Skills</a>
                </NavItem>
                <NavItem>
                    <a href="#projects" onClick={toggle}>Projects</a>
                </NavItem>
                <NavItem>
                    <a href="#contact" onClick={toggle}>Contact</a>
                </NavItem>
            </Nav>
            </Collapse>
        </Navbar>
    );
}

export default Navigation;